import React from 'react';
import {BrowserRouter, Route, Switch, Link} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './redux/store';
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import qs from 'qs';
import {Layout} from 'antd';
import Loadable from 'react-loadable';
const { Header, Footer, Content } = Layout;

function Loading() {
    return <div style={{minHeight: '100vh', minWidth: '100vw', background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <h1>불러오는중...</h1>
    </div>;
}

// 각 회사의 관리자
const Admin = Loadable({
    loader: () => import('./pages/admin/main'),
    loading: Loading,
});
// khra 최고 관리자 (admin)
const KHRA = Loadable({
    loader: () => import('./pages/khra/main'),
    loading: Loading,
});
// 메인 홈 화면
const Home = Loadable({
    loader: () => import('./pages/home'),
    loading: Loading,
});
// 시험 결과
// const Result = Loadable({
//     loader: () => import('./pages/result'),
//     loading: Loading,
// });
// 시험 보는 화면
const Test = Loadable({
    loader: () => import('./pages/test/main'),
    loading: Loading,
});
// 로그인 화면
const Login = Loadable({
    loader: () => import('./pages/login'),
    loading: Loading,
});
// 네브 바
const NavBar = Loadable({
    loader: () => import('./components/navbar/main'),
    loading: Loading,
});

export default class PureRoutes extends React.Component{

    state = {
        route: ''
    }

    render(){

        const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        const naked = parsed.version === 'naked';

        return(
            <BrowserRouter>
                <Provider store={store}>
                   <Layout className="layout" style={{background: '#fff', minHeight: '100vh'}}>
                       {!naked ? <Header style={{background: '#fff', boxShadow: '0 2px 8px #f0f1f2', zIndex: 10}}>
                            {/* console.log(this.props) 은 왜 있는걸까 */}
                            <span style={{float: 'left'}} onClick={() => console.log(this.props)}> 
                                <Link to='/'>
                                    <img src={process.env.PUBLIC_URL + '/resources/navbarlogo.svg'}
                                         style={{width: '200px'}}/>
                                </Link>
                            </span>
                            {/* 네브바 컴포넌트 */}
                           <NavBar location={this.state.route}/>
                       </Header> : ''}
                          <Content id='container-master'>
                            <Route render={({location}) => {
                                if(location !== this.state.route) {
                                    this.setState({route: location});
                                    window.scrollTo(0, 0)}
                                return <TransitionGroup style={{flex: 1}}>
                                    <CSSTransition
                                        key={location.pathname}
                                        classNames="fade"
                                        timeout={300}>
                                        <Switch location={location}>
                                            {/* 최고 관리자 */}
                                            <Route exact path="/khra" render={(props) => <KHRA {...props} />}/>
                                            <Route path="/khra/:id" render={(props) => <KHRA {...props} />}/>
                                            {/* 회사 관리자 */}
                                            <Route exact path="/admin" render={(props) => <Admin {...props} />}/>
                                            <Route exact path="/admin/:id" render={(props) => <Admin {...props} />}/>
                                            {/* 홈 화면 */}
                                            <Route exact path="/" render={(props) => <Home {...props} />}/>
                                            {/* 시험 결과 */}
                                            {/* <Route exact path="/result/:id?" render={(props) => <Result {...props} />}/> */}
                                            {/* 시험 보기 */}
                                            <Route exact path="/test" render={(props) => <Test {...props} />}/>
                                            <Route exact path="/test/:id" render={(props) => <Test {...props} />}/>
                                            {/* 로그인 */}
                                            <Route exact path="/login" render={(props) => <Login {...props} />}/>
                                            <Route render={(props) => <Missing {...props}/>}/>
                                        </Switch>
                                    </CSSTransition>
                                </TransitionGroup>
                            }}/>
                        </Content>
                        {!naked ? <Footer style={{ textAlign: 'center', display: 'flex', justifyContent:'center', backgroundColor:'#f0f2f5', width:'100%'}}>
                            copyright© 2017-2024 atest.co.kr all rights reversed.<br/>
                            문의번호 : 02-3785-3979 / Email : khratest@gmail.com<br/>
                        </Footer> : ''}
                    </Layout>
                </Provider>
            </BrowserRouter>

        )
    }
}

const Missing = (props) => (
    <Layout className="layout">
        <Content style={{ padding: '0 50px' }}>
            <div style={{ background: '#fff', padding: 24, minHeight: 280, marginTop: 16 }}>이페이지는 없는 페이지입니다</div>
        </Content>
    </Layout>
)
